.Counter {
    position: absolute;
    top: var(--standardPadding);
    left: var(--standardPadding);
    width: 1.35em;
    height: 1.35em;
    margin: 0;
    padding: 0;
    background: rgb(var(--mainColor));
    font-size: 1.125em;
    font-weight: var(--font-weight-bold);
    line-height: 1.35em;
    text-align: center;
    color: rgb(var(--invertedTextColor));
    border-radius: 0;

    :global(.theme-vi) & {
        width: 1.25em;
        height: 1.25em;
        margin: var(--tinyPadding);
        background-color: rgb(var(--textColor));
        line-height: 1.25em;
    }

    &.highlighted {
        background-color: rgb(var(--mainColor));
        color: rgb(var(--invertedTextColor));
    }
}
