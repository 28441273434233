.Header {
    display: inline-block;
    width: 100%;
    line-height: var(--heading-line-height);

    a {
        text-decoration: none;
        font-weight: 700;
        height: 0;

        :global(.Icon) {
            margin-top: 2px;
        }

        :global(.theme-gp) & {
            text-transform: uppercase;
            color: rgb(var(--lighterTextColor));
        }

        &:hover {
            cursor: pointer;
        }
    }

    &.column {
        display: flex;
        flex-direction: column;
    }

    &.row {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
        border-bottom: 1px solid rgb(var(--sectionBorder));
    }
}
