/* stylelint-disable selector-class-pattern */
@use 'mixins';

.Disclaimer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: var(--textFont);
    font-weight: var(--font-weight-bold);
    line-height: normal;
    padding-top: var(--standardPadding);
    padding-bottom: var(--standardPadding);

    :global(.betting-disabled) & {
        display: none;
    }

    .settings {
        text-decoration: none;
        display: flex;
        align-items: center;
        padding-right: var(--hugePadding);

        &:hover {
            text-decoration: underline;
            text-underline-offset: 0.25em;
        }

        .settings-text {
            font-size: 0.6em;
            letter-spacing: 0;
            color: rgb(var(--lighterTextColor));
            text-decoration: underline;
            text-transform: uppercase;
            padding-left: var(--tinyPadding);

            :global(.theme-vi) & {
                font-size: var(--caption-medium-font-size);
                text-decoration: none;
                text-transform: capitalize;
            }
        }
    }

    :global(.eighteenPlusTransparent-logo) {
        svg {
            width: auto;
            height: 22px;
            margin-bottom: -0.25em;
        }
    }

    :global(.eighteenPlus-logo) {
        svg {
            width: 7em;
            height: 1.5em;
            margin-bottom: -0.25em;
        }
    }

    &.light {
        .settings {
            .settings-text {
                color: rgb(var(--invertedTextColor));
                text-decoration: none;
            }

            svg > g > path:last-child {
                fill: rgb(var(--invertedTextColor));
            }
        }

        :global(.eighteenPlus-logo) {
            svg {
                > path {
                    fill: rgb(var(--invertedTextColor));
                }
            }
        }
    }

    @include mixins.responsive(m, below) {
        :global(.theme-vi) & {
            .settings {
                padding-right: 0;
                padding-bottom: var(--tinyPadding);
            }
        }
    }
}
