@use 'mixins';

$gutter-width: var(--standardPadding);
$timeline-point-size: var(--standardPadding);
$offset-content: var(--smallPadding);
$offset-x: calc(-1 * ($gutter-width + $offset-content) / 2);
$border-color: rgb(var(--lightSectionBorder));

%-gutter-line {
    position: absolute;
    top: calc(var(--standardPadding) + $timeline-point-size / 2);
    left: 50%;
    width: 1px;
    height: 100%;
    background: $border-color;
    content: '';
    transform: translateX(-50%);
}

%-gutter-line-break {
    content: '';
    position: absolute;
    background: rgb(var(--lightSectionBorder));
    left: 12px;
    width: 12px;
    bottom: 0;
    top: initial;
    height: 1px;
}

.Widget {
    display: block;
    position: relative;
    margin-bottom: var(--largePadding);
    padding: 0;
    z-index: 1;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    opacity: 1;

    --border-thickness: 2px;

    :global(.theme-vi) & {
        --border-thickness: var(--thickerBorderWidth);
    }

    header {
        line-height: 3.5em;
        white-space: nowrap;

        h2 {
            line-height: var(--text-line-height);
        }

        :global(.theme-vi) & {
            a {
                color: rgb(var(--secondColor));
            }
        }
    }

    // FIXME: PB-6012 - Fix this temporary styling for PRO 'uitgelicht' widget
    &-premium {
        :global(.theme-vi) & {
            background: rgb(var(--invertedBackground));

            h4 {
                color: rgb(var(--invertedTextColor));
            }
        }
    }

    &-title {
        display: inline-block;
        white-space: nowrap;
    }

    &-list {
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
        border: 0;
    }

    .border {
        border-bottom: var(--border-thickness) solid rgb(var(--mainColor));
    }

    .border-override {
        border-bottom: var(--border-thickness) solid transparent !important;
    }

    .border-light {
        border-bottom: var(--border-thickness) solid rgb(var(--lightSectionBorder));
    }

    .border-second {
        border-bottom: var(--border-thickness) solid rgb(var(--secondColor));
    }

    .border-third {
        border-bottom: var(--border-thickness) solid rgb(var(--thirdColor));
    }

    .border-fourth {
        border-bottom: var(--border-thickness) solid rgb(var(--warningColor));

        :global(.theme-gp) & {
            border-bottom: 4px solid rgb(var(--fifthColor));
        }
    }

    .hidden {
        display: none;
        position: relative;
        z-index: 0;
        pointer-events: none;
        opacity: 0;
    }

    @include mixins.responsive(m, below) {
        margin: var(--standardPadding);
    }
}
