.Tabs {
    display: flex;
    justify-content: center;
    padding: 0 var(--standardPadding);
    font-weight: 700;
    line-height: 1em;
    list-style: none;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    text-transform: uppercase;
    border: 0;
    border-radius: var(--standardRadius);

    hr {
        color: rgb(var(--lighterTextColor));
    }

    .tab {
        flex: 1;
        padding: var(--standardPadding);
        text-align: center;
        white-space: nowrap;
        color: rgb(var(--lighterTextColor));

        &:hover {
            color: rgb(var(--thirdColor));
        }

        &-highlighted {
            background-color: rgb(var(--invertedTextColor));
            color: rgb(var(--thirdColor));
            border-bottom: 2px solid rgb(var(--thirdColor));
        }
    }

    :global(.theme-gp) & {
        .tab {
            color: rgb(var(--lighterTextColor));

            &:hover {
                color: rgb(var(--mainColor));
            }

            &-highlighted {
                background-color: rgb(var(--invertedTextColor));
                color: rgb(var(--mainColor));
                border-bottom: 2px solid rgb(var(--mainColor));
            }
        }
    }
}
